import React, { useEffect, useState } from 'react';
import './QuickBites.css';
import Title from '../../CommonComponent/Title/Title';
import Carousel from '../../CommonComponent/OwlCarousel/OwlCarousel';
import Modals from '../../CommonComponent/Modal/Modal';
import { useSelector } from 'react-redux';
import { getGroupedOptionsAndAddOns } from '../../../Helper/Coman'
import { useLocation, useNavigate } from 'react-router-dom';
function QuickBites(props) {
    const [show, setShow] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [quickbileFirst, setQuickBitefirst] = useState([])
    const [quickbileSecond, setQuickBiteSecond] = useState([])
    const [item, setItem] = useState([]);
    const [flag, setFlag] = useState(null);
    const { quickBites, menu } = useSelector((state) => state.food);
    const [loader, setLoader] = useState(true)
    const handleClose = () => {
        setShow(false);
    }

    const handleData = (data) => {
        const filteredData = [...data]
        if (filteredData.length > 5) {
            if (filteredData.length < 10) {
                const data1 = [...filteredData]
                const data2 = [...filteredData.reverse()]
                setQuickBitefirst(data1);
                setQuickBiteSecond(data2);
            } else {
                const midpoint = Math.floor(filteredData.length / 2);
                const firstHalf = filteredData.slice(0, midpoint);
                const secondHalf = filteredData.slice(midpoint);
                setQuickBitefirst(firstHalf);
                setQuickBiteSecond(secondHalf);
            }
        } else {
            setQuickBitefirst(filteredData);
            setQuickBiteSecond([]);
        }
    };


    useEffect(() => {
        if (props?.quickBites.length > 0) {
            setQuickBitefirst(props?.quickBites)
            setLoader(false)
        } else {
            setQuickBitefirst([])
            setQuickBiteSecond([])
        }
    }, [props?.quickBites])


    const handleQuickbiteClick = (quickbite) => {
        console.log({ quickbite })
        setFlag('Quickbites');
        const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(menu, quickbite.item_id);
        const data = {
            ...quickbite,
            addOnsGrouped: groupedAddOns,
            optionsGrouped: groupedOptions,
        }
        setItem(data);
        setShow(true);
        window.history.pushState({ modal: true }, '');
    };
  
    useEffect(() => {
        const handlePopState = (event) => {
            if (show) {
                setShow(false)
                event.preventDefault(); // Prevent the default back navigation
            }
        };

        // Add event listener for popstate
        window.addEventListener('popstate', handlePopState);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [show]);

    return (
        <div className="quickbitesslider mb-5">
            {quickBites.length > 0 && <Title title="Quick Bites" className="quicktitle mb-3" />}
            <p className='para'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M7.99844 5.5115V8.00039L9.8651 9.86706M13.5984 8.00039C13.5984 11.0932 11.0912 13.6004 7.99844 13.6004C4.90564 13.6004 2.39844 11.0932 2.39844 8.00039C2.39844 4.9076 4.90564 2.40039 7.99844 2.40039C11.0912 2.40039 13.5984 4.9076 13.5984 8.00039Z" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg> Food Items Ready in 10min. Quick, Fast, Fresh</p>
            {console.log({ quickbileFirst })}
            {quickbileFirst.length > 0 && <Carousel items={quickbileFirst} handleQuickbiteClick={handleQuickbiteClick} isMenu={false} />}
            {/* <Carousel items={quickbileSecond} handleQuickbiteClick={handleQuickbiteClick} isMenu={false} /> */}
            <Modals
                item={item}
                show={show}
                onHide={handleClose}
                handleIconClick={() => setIsFilled(!isFilled)}
                isFilled={isFilled}
                flag={flag}
            />
        </div>
    );
}

export default QuickBites;
